import { Checkbox, FormControlLabel, styled } from "@mui/material";
import { useSelector } from "react-redux";

const CheckboxInput = ({ checked, label, onChange, color, ...rest }) => {
  const userState = useSelector(state => state?.userState);
  const theme = userState?.publisher?.theme_color;
  return label ? (
    <FormControlLabel
      control={
        <CustomCheckbox
          customColor={color || theme}
          checked={checked}
          onChange={onChange}
          {...rest}
        />
      }
      {...rest}
      label={label}
    />
  ) : (
    <CustomCheckbox
      customColor={color || theme}
      checked={checked}
      onChange={onChange}
      {...rest}
    />
  );
};

const CustomCheckbox = styled(Checkbox)(({ customColor }) => ({
  "&.MuiCheckbox-root": {
    color: customColor || "#111827",
    "&.Mui-checked": {
      color: customColor || "#111827",
    },
  },
}));

export default CheckboxInput;
