import { Button, CircularProgress, styled } from "@mui/material";
import { useSelector } from "react-redux";

const PrimaryButton = ({
  label,
  onClick,
  startIcon,
  endIcon,
  loading,
  disabled,
  color,
  width,
  height,
  size,
  textColor,
  children,
  border,
  className,
  ...rest
}) => {
  const userState = useSelector(state => state?.userState);
  const theme = userState?.publisher?.theme_color;
  return (
    <CustomButton
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      customColor={color || theme}
      customWidth={width}
      textColor={textColor}
      customHeight={height}
      className={className}
      size={size}
      startIcon={loading ? <CircularProgress size={16} /> : startIcon}
      endIcon={endIcon}
      border={border}
      {...rest}
    >
      {label || children}
    </CustomButton>
  );
};
const CustomButton = styled(Button)(
  ({ customColor, customWidth, customHeight, textColor, border }) => ({
    width: customWidth || "auto",
    minWidth: 80,
    height: customHeight || "auto",
    color: textColor,
    backgroundColor: `${customColor || "#111827"}`,
    textTransform: "none",
    "&:hover": {
      backgroundColor: `${customColor || "#111827"}`,
    },
    border
  })
);

export default PrimaryButton;
