import { Container, Divider, Tooltip } from "@material-ui/core";
import { Box, Checkbox, Grid, styled } from "@mui/material";
import axios from "axios";
import {
    ArrayChip,
    AutoCompleteComponent,
    ButtonGroupTop,
    ModalError,
    ModalSuccess,
    ModalWarning,
    Page,
    PrimaryButton,
    SearchTextInput,
    SecondaryButton,
    SelectInput,
    SkeletonComponent,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { breadcrumbData } from "../Components/SongClaimMenu";
import { act } from "react";

function OverClaim() {
    const classes = globalStyles();
    const userRole = localStorage?.getItem("role");
    const roleSuperAdmin = userRole === "admin";
    const roleAssociation = userRole === "association";
    const token = localStorage.getItem("token");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const [loadingPage, setLoadingPage] = useState(false);
    const [loadingFilter, setLoadingFilter] = useState({
        composer: false,
        publisher: false
    });
    const [loadingButton, setLoadingButton] = useState(false);
    const [optionPublisher, setOptionPublisher] = useState([]);
    const [optionComposer, setOptionComposer] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [queryFilter, setQueryFilter] = useState({
        publisher: "",
        composer: ""
    });
    const [queryParams, setQueryParams] = useState({
        page: 1,
        size: 10,
        search: "",
        publisher_id: "",
        composer_id: "",
    });
    const [tableTotalPage, setTableTotalPage] = useState(1);
    const [selectedSong, setSelectedSong] = useState([]);

    const handleChangeQueryParams = (value, key) => {
        setQueryParams(currentState => ({
            ...currentState,
            ...(key !== "page" && { page: 1 }),
            [key]: value,
        }));
    };
    const handleChangeQueryFilter = (value, key) => {
        setQueryFilter(currentState => ({
            ...currentState,
            [key]: value,
        }));
    };

    const handleSelectSong = (event, option) => {
        const { checked } = event?.target || false;
        setSelectedSong(prev =>
            checked
                ? [...prev, option]
                : prev.filter(item => item.song_id !== option.song_id)
        );
    };
    const handleVerifySong = (value, option) => {
        setDataTable(prev =>
            prev.map(song =>
                song?.song_id === option?.song_id
                    ? { ...song, publisher_id: value }
                    : song
            )
        );
        setSelectedSong(prev =>
            prev.map(song =>
                song?.song_id === option?.song_id
                    ? { ...song, publisher_id: value }
                    : song
            )
        );
    };

    const getDataTable = async () => {
        try {
            setLoadingPage(true);
            const res = await axios.get(`${hardBaseUrl}/double-claim/index`, {
                headers,
                params: queryParams,
            });

            const { data, meta } = res?.data;
            setDataTable(data);
            const pageCount = getTotalPage(meta?.total, queryParams?.size || 1);
            setTableTotalPage(pageCount);
        } catch (error) {
            ModalError(getErrors(error?.response));
        } finally {
            setLoadingPage(false);
        }
    };
    const getOptionPublisher = async () => {
        try {
            setLoadingFilter((state) => ({
                ...state,
                publisher: true
            }));
            const res = await axios.get(`${hardBaseUrl}/double-claim/publisher`, {
                headers,
            });
            const modifiedData = res?.data?.data?.map(item => ({
                ...item,
                id: item?.publisher_id,
                label: item?.name,
            }));

            setOptionPublisher(modifiedData);
        } catch (error) {
            ModalError(getErrors(error?.response));
        } finally {
            setLoadingFilter((state) => ({
                ...state,
                publisher: false
            }));
        }
    };
    const getOptionComposer = async (searchComposer) => {
        try {
            setLoadingFilter((state) => ({
                ...state,
                composer: true
            }));
            const params = {
                search: searchComposer,
                page: 1,
                ...(roleSuperAdmin || roleAssociation
                    ? { size: 50, sort: 0 }
                    : { "double-claim": true }),
            };
            const filterParams = Object.fromEntries(
                Object.entries(params).filter(item => item[1])
            );
            const res = await axios.get(`${hardBaseUrl}/publisher/composer`, {
                headers,
                params: filterParams
            });
            const modifiedData = res?.data?.data?.map(item => ({
                ...item,
                id: item?.composer_id,
                label: item?.sure_name,
            }));

            setOptionComposer(modifiedData);
        } catch (error) {
            ModalError(getErrors(error?.response));
        } finally {
            setLoadingFilter((state) => ({
                ...state,
                composer: false
            }));
        }
    };

    const debounceDataTable = useCallback(
        debounce(() => {
            getDataTable();
        }, 500),
        [queryParams]
    );

    const debounceDataComposer = useCallback(
        debounce(() => {
            getOptionComposer(queryFilter?.composer);
        }, 500),
        [queryFilter?.composer]
    );

    const debounceDataPublisher = useCallback(
        debounce(() => {
            getOptionPublisher(queryFilter?.publisher);
        }, 500),
        [queryFilter?.publisher]
    );

    const handleDownloadDocument = async () => {
        try {
            setLoadingButton(true);
            const { data } = await axios.post(
                `${hardBaseUrl}/double-claim/export`,
                {
                    publisher_id: queryParams?.publisher_id || 0,
                    composer_id: queryParams?.composer_id || 0,
                },
                { headers }
            );
            ModalSuccess(data?.message, "Data is being processed").then(
                res => res.isConfirmed && window.location.reload()
            );
        } catch (error) {
            ModalError(getErrors(error?.response));
        } finally {
            setLoadingButton(false);
        }
    };
    const handleSubmit = async () => {
        const payload = selectedSong?.map(item => ({
            song_id: item?.song_id,
            publisher_id: item?.publisher_id,
        }));
        try {
            setLoadingPage(true);
            const { data } = await axios.post(
                `${hardBaseUrl}/double-claim/verify`,
                payload,
                { headers }
            );
            ModalSuccess(data?.message, "Data is being processed").then(
                res => res.isConfirmed && window.location.reload()
            );
        } catch (error) {
            ModalError(getErrors(error?.response));
        } finally {
            setLoadingPage(false);
        }
    };

    useEffect(() => {
        getOptionComposer();
        getOptionPublisher();
    }, []);

    useEffect(() => {
        debounceDataPublisher();
        return () => {
            debounceDataPublisher.cancel();
        };
    }, [queryFilter?.publisher]);

    useEffect(() => {
        debounceDataComposer();
        return () => {
            debounceDataComposer.cancel();
        };
    }, [queryFilter?.composer]);

    useEffect(() => {
        debounceDataTable();
        return () => {
            debounceDataTable.cancel();
        };
    }, [queryParams, debounceDataTable]);

    return (
        <Page className={classes.root} title="Overclaim">
            <Container maxWidth={false}>
                <HeaderTitle title="Overclaim" breadcrumbData={breadcrumbData} />
                <Divider className={classes.divider} />
                <ButtonGroupTop />
                <Box mb="20px">
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <SearchTextInput
                                placeholder="Search"
                                value={queryParams?.search}
                                onChange={e => handleChangeQueryParams(e?.target?.value, "search")}
                            />
                        </Grid>
                        <Grid item>
                            <Grid container columnSpacing={1} alignItems="center">
                                <Grid item>
                                    <AutoCompleteComponent
                                        options={optionPublisher}
                                        size="small"
                                        label="Publisher"
                                        changeSearch={(e) => handleChangeQueryFilter(e?.target?.value, "publisher")}
                                        loading={loadingFilter?.publisher}
                                        value={
                                            optionPublisher.find(
                                                option => option.id === queryParams?.publisher_id
                                            ) || null
                                        }
                                        renderOption={(props, option) => {
                                            return (
                                                <Box component='li' {...props} key={option?.publisher_id}>
                                                    {option?.name}
                                                </Box>
                                            );
                                        }}
                                        onChange={value =>
                                            handleChangeQueryParams(value, "publisher_id")
                                        }
                                        width={300}
                                    />
                                </Grid>
                                <Grid item>
                                    <AutoCompleteComponent
                                        options={optionComposer}
                                        size="small"
                                        label="Composer"
                                        loading={loadingFilter?.composer}
                                        changeSearch={(e) => handleChangeQueryFilter(e?.target?.value, "composer")}
                                        value={
                                            optionComposer.find(
                                                option => option.id === queryParams?.composer_id
                                            ) || null
                                        }
                                        renderOption={(props, option) => {
                                            return (
                                                <Box component='li' {...props} key={option?.composer_id}>
                                                    {option?.sure_name}
                                                </Box>
                                            );
                                        }}
                                        onChange={value =>
                                            handleChangeQueryParams(value, "composer_id")
                                        }
                                        width={300}
                                    />
                                </Grid>
                                <Tooltip title="Download Document">
                                    <Grid item>
                                        <PrimaryButton
                                            label="Download"
                                            disabled={loadingButton}
                                            loading={loadingButton}
                                            onClick={handleDownloadDocument}
                                            size="large"
                                        />
                                    </Grid>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                {loadingPage ? (
                    <SkeletonComponent variant="wave" />
                ) : (
                    <InnoTableV2
                        isLoading={false}
                        columns={columnTable({
                            handleVerifySong,
                            selectedSong,
                            roleAssociation,
                            roleSuperAdmin,
                        })}
                        items={dataTable || []}
                        page={queryParams?.page}
                        rowsPerPage={queryParams?.size}
                        totalPage={tableTotalPage}
                        handleChangePage={(_, page) =>
                            handleChangeQueryParams(page, "page")
                        }
                        handleChangeRowsPerPage={e =>
                            handleChangeQueryParams(e?.target?.value, "size")
                        }
                        isHaveAction={roleAssociation}
                        renderAction={item => {
                            return (
                                <CustomCheckbox
                                    checked={selectedSong?.some(
                                        selected => selected?.song_id === item?.song_id
                                    )}
                                    onChange={e => handleSelectSong(e, item)}
                                />
                            );
                        }}
                    />
                )}
                {selectedSong?.length > 0 && (
                    <Grid container justifyContent="right" columnSpacing={1} mt="16px">
                        <Grid item>
                            <SecondaryButton
                                label="Cancel"
                                onClick={() => window.location.reload()}
                            />
                        </Grid>
                        <Grid item>
                            <PrimaryButton
                                label="Confirm"
                                onClick={() =>
                                    ModalWarning(
                                        "Are you sure to confirm Double Claim this song ?",
                                        "Confirm Double Claim"
                                    ).then(res => res?.isConfirmed && handleSubmit())
                                }
                            />
                        </Grid>
                    </Grid>
                )}
            </Container>
        </Page>
    );
}

const CustomCheckbox = styled(Checkbox)(({ customColor }) => ({
    "&.MuiCheckbox-root": {
        color: customColor || "#111827",
        "&.Mui-checked": {
            color: customColor || "#111827",
        },
    },
}));
const columnTable = ({
    selectedSong,
    handleVerifySong,
    roleAssociation,
    roleSuperAdmin,
}) => [
        {
            name: "publisher_name",
            title: "Publisher Name",
            renderText: item => item || "-",
        },
        ...(roleAssociation || roleSuperAdmin
            ? []
            : [
                {
                    name: "publishers",
                    title: "Other Publisher",
                    renderText: item =>
                        item?.map(publisher => publisher?.name)?.join("; ") || "-",
                },
            ]),
        {
            name: "song_title",
            title: "Song Title",
            renderText: item => (item ? item?.replace(/, /g, " ; ") : "-"),
        },
        {
            name: "composer_name",
            title: "Composer/Author",
            renderText: item => <ArrayChip xs={12} list={item?.split(/[,;]/)} max={2} />,
        },
        {
            name: "iswc_code",
            title: "ISWC",
            renderText: item => (item ? item?.replace(/, /g, " ; ") : "-"),
        },
        {
            name: "isrc_code",
            title: "ISRC",
            renderText: item => <ArrayChip list={item?.split(";")} max={2} />,
        },
        {
            name: "workcodes",
            title: "Workcode",
            renderText: item =>
                item ? (item?.length > 1 ? item?.join(" ; ") : item) : "-",
        },
        {
            name: "ownership_percentage",
            title: "Ownership Percentage",
            renderText: item =>
                item && item?.length > 1
                    ? item.map(el => el + "%").join(" ; ")
                    : item + "%",
        },
        ...(roleAssociation
            ? [
                {
                    name: "all",
                    title: "Verified",
                    renderText: item => {
                        const selected = selectedSong?.some(
                            selected => selected?.song_id === item?.song_id
                        );
                        return selected ? (
                            <SelectInput
                                disabled={
                                    !selectedSong?.some(
                                        selected => selected?.song_id === item?.song_id
                                    )
                                }
                                label={item?.publisher_id ? null : "Verified"}
                                value={item?.publisher_id}
                                onChange={e => handleVerifySong(e.target.value, item)}
                                options={item?.publishers}
                                optionKey="publisher_id"
                                optionLabel="name"
                                width={180}
                            />
                        ) : (
                            <Box width={180} />
                        );
                    },
                },
            ]
            : []),
    ];
export default OverClaim;
