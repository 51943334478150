export const topMenuButton = (unclaimPermission, isAdmin) => [
  ...(unclaimPermission
    ? [
      {
        id: 1,
        title: "Unclaim",
        link: "/admin/publisher/song-unclaim",
      },
      {
        id: 2,
        title: "Double Claim",
        link: "/admin/publisher/double-claim",
      },
      {
        id: 3,
        title: "Unclaim Monitoring",
        link: "/admin/publisher/unclaim-monitoring",
      },
    ]
    : [
      {
        id: 1,
        title: "Double Claim",
        link: "/admin/publisher/double-claim",
      },
      ...(isAdmin
        ? [
          {
            id: 2,
            title: "Unclaim",
            link: "/admin/song-unclaim",
          },
        ]
        : []),
    ]),
];

export const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Song Claim",
    active: true,
  },
];
