import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Skeleton,
} from "@mui/material";
import axios from "axios";
import {
  FormLabel,
  ModalError,
  ModalSuccess,
  PasswordInput,
  PrimaryButton,
  SecondaryButton,
  TextInput,
} from "components";
import { Fragment, useEffect, useState } from "react";
import { hardBaseUrl } from "services/urlConstant";
import { getErrors, validateEmail } from "utils";

const ModalFormParameterAssociation = ({ open, onClose, preload, getData }) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [passwordError, setPasswordError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [payload, setPayload] = useState({
    email: "",
    name: "",
    phone_number: "",
    pic: "",
    association_code: "",
    ...(!preload && { password: "", re_password: "" }),
  });

  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
    if (!preload && (key === "password" || key === "confirm_password")) {
      setPasswordError(null);
    }
    if (key === "email") {
      setEmailError(null);
    }
  };

  const getDetail = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/association/detail/${preload}`,
        {
          headers,
        }
      );
      setPayload(res?.data?.data);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setLoadingPage(false);
    }
  };
  const handleSubmit = async () => {
    const method = preload ? axios.put : axios.post;
    const {
      password,
      re_password,
      association_id,
      email,
      ...restPayload
    } = payload;
    const modifiedPayload = {
      ...restPayload,
      email,
      ...(!preload && { password, re_password }),
    };
    let hasError = false;
    setPasswordError(null);
    setEmailError(null);

    if (!preload && password !== re_password) {
      setPasswordError("Password and Confirm Password does not match");
      hasError = true;
    }

    if (!validateEmail(email)) {
      setEmailError(
        "Invalid email, please enter your email address in format: yourname@example.com"
      );
      hasError = true;
    }

    if (hasError) return;

    try {
      setLoadingButton(true);
      await method(
        `${hardBaseUrl}/association/${
          preload ? `update/${association_id}` : "create"
        }`,
        modifiedPayload,
        { headers }
      );
      onClose();
      ModalSuccess(
        `Successfully ${preload ? "Update" : "Create"} Association`
      ).then(() => {
        setPayload({});
        getData();
      });
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setLoadingButton(false);
    }
  };

  useEffect(() => {
    if (preload) {
      getDetail();
    }
  }, [preload]);

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{`${preload ? "Edit" : "Add"} Association`}</DialogTitle>
      <DialogContent dividers>
        {loadingPage ? (
          <Grid container direction="column" rowSpacing={2}>
            {Array.from({ length: 5 }).map((_, index) => (
              <Grid item key={index}>
                <Box mb="8px">
                  <Skeleton variant="rounded" width="40%" height={25} />
                </Box>
                <Skeleton variant="rounded" width="100%" height={30} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container direction="column" rowSpacing={2}>
            <Grid item>
              <FormLabel label="Association Name" required />
              <TextInput
                placeholder="Association Name"
                value={payload?.name}
                onChange={e => handleChangePayload(e?.target?.value, "name")}
                disabled={loadingButton}
              />
            </Grid>
            <Grid item>
              <FormLabel label="Transmitter Code" />
              <TextInput
                placeholder="Transmitter Code"
                value={payload?.association_code}
                onChange={e =>
                  handleChangePayload(e?.target?.value, "association_code")
                }
                disabled={loadingButton}
              />
            </Grid>
            <Grid item>
              <FormLabel label="PIC" required />
              <TextInput
                placeholder="PIC"
                value={payload?.pic}
                onChange={e => handleChangePayload(e?.target?.value, "pic")}
                disabled={loadingButton}
              />
            </Grid>
            <Grid item>
              <FormLabel label="Email" required />
              <TextInput
                placeholder="Email"
                value={payload?.email}
                onChange={e => handleChangePayload(e?.target?.value, "email")}
                error={!!emailError}
                helperText={emailError}
                disabled={loadingButton}
              />
            </Grid>
            <Grid item>
              <FormLabel label="Phone Number" required />
              <TextInput
                placeholder="Phone Number"
                value={payload?.phone_number}
                onChange={e =>
                  handleChangePayload(e?.target?.value, "phone_number")
                }
                type="number"
                    disabled={loadingButton}
              />
            </Grid>
            {!preload && (
              <Fragment>
                <Grid item>
                  <FormLabel label="Password" required />
                  <PasswordInput
                    placeholder="Password"
                    value={payload?.password}
                    onChange={e =>
                      handleChangePayload(e?.target?.value, "password")
                    }
                    autoComplete="new-password"
                    error={!!passwordError}
                    helperText={passwordError}
                    disabled={loadingButton}
                  />
                </Grid>
                <Grid item>
                  <FormLabel label="Confirm Password" required />
                  <PasswordInput
                    placeholder="Confirm Password"
                    value={payload?.re_password}
                    error={!!passwordError}
                    helperText={passwordError}
                    onChange={e =>
                      handleChangePayload(e?.target?.value, "re_password")
                    }
                    disabled={loadingButton}
                  />
                </Grid>
              </Fragment>
            )}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <SecondaryButton
          label="Cancel"
          disabled={loadingPage}
          onClick={onClose}
        />
        <PrimaryButton
          label={loadingButton ? "Saving" : "Save"}
          loading={loadingButton}
          disabled={loadingPage || loadingButton}
          onClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ModalFormParameterAssociation;
