import {
  Box,
  Grid,
  Pagination,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { SelectInput, TableAction } from "components";
import { getCookie } from "utils";

const CustomTable = ({
  columnTable,
  data,
  rowClick,
  emptyPlaceHolder,
  page,
  pageSize,
  pageCount,
  handleChangePage,
  handleChangePageSize,
  isLoading,
  color,
  maxHeight,
  tableSize,
  isHaveAction,
  renderAction,
  handleView,
  deleteConfirmation,
  deleteConfirmationContent,
  deleteConfirmationKey,
  deleteConfirmationTitle,
  handleRestore,
  restoreConfirmation,
  restoreConfirmationContent,
  restoreConfirmationKey,
  restoreConfirmationTitle,
  tooltipDelete,
  tooltipEdit,
  tooltipRestore,
  tooltipView,
  handleEdit,
  handleDelete,
}) => {
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  return isLoading ? (
    <Skeleton
      variant="rounded"
      height={maxHeight + 74 || 300}
      animation="wave"
    />
  ) : (
    <Box border="1px solid #ebebeb" borderRadius="6px">
      <CustomTableContainer maxHeight={maxHeight}>
        <Table stickyHeader size={tableSize}>
          <TableHead>
            <TableRow>
              {isHaveAction && (
                <TableHeadCell width={150}>
                  <Typography fontSize="14px" color="#687083" fontWeight="bold">
                    Action
                  </Typography>
                </TableHeadCell>
              )}
              {columnTable?.map((item, index) => (
                <TableHeadCell
                  key={index}
                  width={item?.width}
                  align={item?.headerAlign}
                >
                  <Typography fontSize="14px" color="#687083" fontWeight="bold">
                    {item?.title}
                  </Typography>
                </TableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length > 0 ? (
              data?.map((value, index) => (
                <TableRow
                  hover
                  key={index}
                  style={
                    rowClick && {
                      cursor: "pointer",
                    }
                  }
                  onClick={event => {
                    if (rowClick) {
                      event.preventDefault();
                      rowClick(value);
                    }
                  }}
                >
                  {isHaveAction && (
                    <TableBodyCell maxWidth={`${1 / columnTable?.length}%`}>
                      {renderAction ? (
                        renderAction(value)
                      ) : (
                        <TableAction
                          {...{
                            handleView: handleView && (() => handleView(value)),
                            handleRestore:
                              handleRestore && (() => handleRestore(value)),
                            handleEdit: handleEdit && (() => handleEdit(value)),
                            handleDelete:
                              handleDelete && (() => handleDelete(value)),
                            deleteConfirmation,
                            restoreConfirmation,
                            deleteConfirmationContent:
                              deleteConfirmationContent &&
                              (() => deleteConfirmationContent(value)),
                            deleteConfirmationKey:
                              deleteConfirmationKey &&
                              (() => deleteConfirmationKey(value)),
                            deleteConfirmationTitle:
                              deleteConfirmationTitle &&
                              (() => deleteConfirmationTitle(value)),
                            restoreConfirmationContent:
                              restoreConfirmationContent &&
                              (() => restoreConfirmationContent(value)),
                            restoreConfirmationKey:
                              restoreConfirmationKey &&
                              (() => restoreConfirmationKey(value)),
                            restoreConfirmationTitle:
                              restoreConfirmationTitle &&
                              (() => restoreConfirmationTitle(value)),
                            tooltipDelete:
                              tooltipDelete && (() => tooltipDelete(value)),
                            tooltipEdit:
                              tooltipEdit && (() => tooltipEdit(value)),
                            tooltipRestore:
                              tooltipRestore && (() => tooltipRestore(value)),
                            tooltipView:
                              tooltipView && (() => tooltipView(value)),
                          }}
                        />
                      )}
                    </TableBodyCell>
                  )}
                  {columnTable?.map((item, idx) => (
                    <TableBodyCell
                      key={idx}
                      width={item?.width}
                      maxWidth={!item?.width && `${1 / columnTable?.length}%`}
                    >
                      <Typography fontSize="14px" color="#111827">
                        {item?.renderText
                          ? item?.renderText(
                            item?.name !== "all"
                              ? value?.[item?.name]
                              : value || "-"
                          )
                          : value?.[item?.name] || "-"}
                      </Typography>
                    </TableBodyCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={12}>
                  <center>
                    <Typography
                      fontWeight={400}
                      color="#687083"
                      fontSize="14px"
                      fontStyle="italic"
                    >
                      {emptyPlaceHolder || "No Data"}
                    </Typography>
                  </center>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CustomTableContainer>
      {pageCount > 0 && (
        <Box p="16px 24px">
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid container columnSpacing={1} alignItems="center">
                <Grid item>
                  <Typography color="#687083" font-size="14px">
                    Item per page:
                  </Typography>
                </Grid>
                <Grid item>
                  <SelectInput
                    onChange={handleChangePageSize}
                    options={pageSizeList}
                    value={pageSize}
                    optionKey="name"
                    optionLabel="name"
                    width="auto"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <CustomPagination
                count={pageCount}
                shape="rounded"
                customColor={color || userLogin?.publisher?.theme_color}
                page={page}
                onChange={handleChangePage}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};
const CustomTableContainer = styled(TableContainer)(({ maxHeight }) => ({
  maxHeight: maxHeight,
  borderRadius: "6px",
}));
const CustomPagination = styled(Pagination)(({ customColor }) => ({
  "& .MuiButtonBase-root.Mui-selected": {
    backgroundColor: `${customColor || "#111827"}`,
    color: "white",
    "&:hover": {
      backgroundColor: `${customColor || "#111827"}`,
    },
  },
}));
const TableHeadCell = styled(TableCell)(({ width, maxWidth }) => ({
  backgroundColor: "#fafafa",
  width: width || "auto",
  maxWidth: maxWidth,
}));
const TableBodyCell = styled(TableCell)(({ width, maxWidth }) => ({
  width: width || "auto",
  maxWidth: maxWidth,
}));
const pageSizeList = [{ name: 5 }, { name: 10 }, { name: 20 }, { name: 50 }];

export default CustomTable;
