import {
  AppBar,
  Avatar,
  Badge,
  ClickAwayListener,
  Grow,
  Hidden,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
  colors,
} from "@material-ui/core";
import { ExitToApp, Person, Settings } from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography } from "@mui/material";
import Axios from "axios";
import clsx from "clsx";
import { SelectInput } from "components";
import { InnoImage } from "inno-ui";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import BellBottom from "../../assets/img/bellBottom.png";
import BellPng from "../../assets/img/bellPng.png";
import MpisNewLogo from "../../assets/img/mpisNewLogo.png";
import NotificationsPopover from "../../components/molecules/NotificationsPopover";
import { logout } from "../../redux/actions/auth";
import { hardBaseUrl, hardSubDomain } from "../../services/urlConstant";
import { clearCookie } from "../../utils/constants";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none",
    backgroundColor: "#FFFFFF",
    borderBottom: "1px solid #E4E7EB",
  },
  containerTopBar: {
    width: "208px",
    height: "64px",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  },
  flexGrow: {
    flexGrow: 1,
  },
  search: {
    backgroundColor: "rgba(255,255,255, 0.1)",
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: "inherit",
  },
  searchInput: {
    flexGrow: 1,
    color: "inherit",
    "& input::placeholder": {
      opacity: 1,
      color: "inherit",
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900],
    },
  },
  trialIcon: {
    marginRight: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(1),
    color: "#111827",
  },
  chatButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
    color: "#787a78",
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
    color: "white",
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
  profile: {
    paddingTop: theme.spacing(1.3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  name: {
    fontSize: "16px",
    fontWeight: 500,
  },
  email: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#737373",
    marginBottom: "6px",
  },
  avatar: {
    marginLeft: theme.spacing(1.5),
  },
}));
function TopBar({
  onOpenNavBarMobile,
  className,
  userProfileImage,
  userFirstLetter,
  notifications,
  notificationStatus,
  theRole,
  theName,
  nameOfRole,
  imgOfPublisher,
  userLogin,
  ...rest
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const notificationsRef = useRef(null);
  const roleId = localStorage?.getItem("role_id");
  const isComposer = theRole === "composer";
  const typeWeb = localStorage.getItem("typeWeb") || "mpis";
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [openNotifications, setOpenNotifications] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [composerList, setComposerList] = useState([]);
  const optionRole = isComposer ? composerList : userLogin?.user_roles;
  const userLoginRole = userLogin?.role?.type;

  const handleLogout = async () => {
    dispatch(logout());
    const defaultOptions = {
      baseURL: `${hardBaseUrl}/auth/logout`,
      headers,
    };
    let url_after_logout = localStorage.getItem("url_login");
    let instance = Axios.create(defaultOptions);
    instance.interceptors.request.use(function (config) {
      config.headers.Authorization = token ? `Bearer ${token}` : "";
      return config;
    });
    const response = await instance.post();
    if (response.data.message === "success") {
      clearCookie();
      history.push(url_after_logout);
    }
  };

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const getDetail = () => {
    Axios.get(`${hardBaseUrl}/composer-manager/composers`, { headers }).then(
      res => {
        let theArr = res.data.data;
        let newArr = [];
        theArr.forEach(item => {
          let tempObj = {
            composer_id: item.composer_id,
            composer_name: item.composer_name,
            is_current_composer: item.is_current_composer,
            selected: false,
          };
          newArr.push(tempObj);
        });
        setComposerList(newArr);
      }
    );
  };
  useEffect(() => {
    if (userLogin !== null) {
      if (userLogin.role.type === "composer") {
        getDetail();
      }
    }
  }, [userLogin]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickProfile = () => {
    setAnchorEl(null);
    const url = userLoginRole === "composer" ? "/pencipta/profile-pencipta" : "/admin/profile";
    history.push(url);
  };

  const handleKonfigurasi = e => {
    if (userLoginRole === "admin") {
      history.push("/admin/konfigurasi/sistem");
    } else if (userLoginRole === "publisher") {
      if (hardSubDomain !== null) {
        history.push("/admin/konfigurasi/personalisasi");
      } else {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };

  const getLogo = () => {
    if (typeWeb === "caris") {
      if (userLoginRole === "publisher" || userLoginRole === "composer") {
        if (nameOfRole === "Role Pencipta" || nameOfRole === "Pencipta") {
          return <img alt="Logos" src={imgOfPublisher} height={65} />;
        } else {
          if (userLogin?.publisher?.profile_image === "") {
            return (
              <div>
                <span>{theName}</span>
              </div>
            );
          } else {
            return (
              <InnoImage
                alt="Logo"
                src={userLogin?.publisher?.profile_image}
                styleImage={{
                  maxWidth: "174px",
                  width: "100%",
                  height: "auto",
                  maxHeight: "80px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              />
            );
          }
        }
      } else {
        return (
          <Box>
            <img alt="Logos" src={MpisNewLogo} />
          </Box>
        );
      }
    } else {
      return <img alt="Logos" src={MpisNewLogo} rel="preload" />;
    }
  };

  const GetKonfigurasiMenu = () => {
    if (
      (typeWeb === "mpis" && userLoginRole === "admin") ||
      (typeWeb === "caris" && userLoginRole !== "composer")
    ) {
      return (
        <MenuItem onClick={e => handleKonfigurasi(e)}>
          <Box color="#a3a3a3" mr="8px">
            <Settings />
          </Box>
          Configuration
        </MenuItem>
      );
    }
  };

  const handleChangeComposer = async id => {
    try {
      let resultLogin = await Axios.put(
        `${hardBaseUrl}/composer-manager/set-composer/${id}`,
        { headers }
      );
      if (resultLogin.data.message === "success") {
        localStorage.setItem("token", resultLogin.data.data.access_token);
        if (history.location.pathname === "/pencipta/dashboard-pencipta") {
          window.location.reload();
        } else {
          history.push("/pencipta/dashboard-pencipta");
        }
      }
    } catch (error) {
      new Error(error);
    }
  };

  const handleRedirectAddPayment = () => {
    history.push({
      pathname: "/admin/payment/add",
    });
    handleNotificationsClose();
  };

  const handleChangeRole = id => {
    if (roleId !== String(id)) {
      localStorage.setItem("role_id", id);
      Axios.post(`${hardBaseUrl}/switch-role`, { role_id: id }, { headers });
      window.location.reload();
    }
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onOpenNavBarMobile}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <div className={classes.containerTopBar}>
          <RouterLink
            to={
              isComposer ? "/pencipta/dashboard-pencipta" : "/admin/dashboard"
            }
          >
            {getLogo()}
          </RouterLink>
        </div>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          {optionRole?.length > 1 ? (
            <SelectInput
              value={roleId}
              options={optionRole}
              optionKey={isComposer ? "composer_id" : "role_id"}
              optionLabel={isComposer ? "composer_name" : "name"}
              onChange={e =>
                isComposer
                  ? handleChangeComposer(e?.target?.value)
                  : handleChangeRole(e?.target?.value)
              }
              width={250}
            />
          ) : (
            <Typography fontSize="18px" fontWeight={500} color="#263238">
              {isComposer ? userLogin?.name : optionRole?.[0]?.name}
            </Typography>
          )}

          <IconButton
            className={classes.notificationsButton}
            color="inherit"
            onClick={handleNotificationsOpen}
            ref={notificationsRef}
          >
            <Badge
              badgeContent={notificationStatus}
              classes={{ badge: classes.notificationsBadge }}
            >
              <Box mt="-15px">
                <img src={BellPng} alt="notification" />
                <Box m="-25px 0 -15px">
                  <img src={BellBottom} alt="notification" />
                </Box>
              </Box>
            </Badge>
          </IconButton>
        </Hidden>
        <IconButton
          data-testid="data-test-button-open-profile"
          onClick={handleClick}
        >
          <Avatar alt="Ava" src={userProfileImage || userFirstLetter} />
        </IconButton>
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          role={undefined}
          placement="bottom-end"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <Box padding="10px 16px" borderBottom="1px solid #e1e1e1">
                  <Typography fontSize={16} fontWeight={500}>
                    {userLogin?.name}
                  </Typography>
                  <Typography fontSize={14} fontWeight={500} color="#737373">
                    {userLogin?.email}
                  </Typography>
                </Box>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="simple-menu">
                    <MenuItem onClick={handleClickProfile}>
                      <Box color="#a3a3a3" mr="8px">
                        <Person color="inherit" />
                      </Box>
                      Profile
                    </MenuItem>
                    <GetKonfigurasiMenu />
                    <MenuItem
                      onClick={handleLogout}
                      data-testid="data-test-button-logout"
                    >
                      <Box display="flex" color="red" gap="8px">
                        <ExitToApp />
                        Logout
                      </Box>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Toolbar>
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
        markAsRead={handleRedirectAddPayment}
      />
    </AppBar>
  );
}

TopBar.propTypes = {
  userFirstLetter: PropTypes.string,
  userProfileImage: PropTypes.string,
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
  // loadingComponent: PropTypes.bool,
};

export default TopBar;
