import { useState, useEffect } from "react";
import axios from "axios";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Box,
} from "@mui/material";
import { hardBaseUrl } from "services/urlConstant";
import {
    FormLabel,
    PrimaryButton,
    SecondaryButton,
    TextInput,
    AutoCompleteComponent
} from "components";

const ModalAssetId = ({
    open,
    onClose,
    preload,
    handleSubmit,
}) => {
    const token = localStorage.getItem("token");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const [value, setValue] = useState({
        asset_code: "",
        dsp: ""
    });
    const { asset_code, dsp } = value;
    const [listDSP, setListDsp] = useState([]);
    const changeValue = (key, value) => setValue((state) => ({
        ...state,
        [key]: value
    }));
    const getDsps = async () => {
        try {
            const res = await axios.get(`${hardBaseUrl}/dashboard/options/dsp`, { headers });
            const data = res?.data?.data?.map(({ dsp_id, name }) => ({
                id: dsp_id,
                label: name
            }));
            setListDsp(data);
        } catch (err) {
            new Error(err);
        }

    };
    useEffect(() => {
        setValue((state) => ({
            ...state,
            asset_code: preload?.asset_code,
            dsp: preload?.dsp_id
        }));
    }, [preload]);

    useEffect(() => {
        getDsps();
    }, []);

    const onSubmit = (e) => {
        e?.preventDefault();
        handleSubmit({
            ...preload,
            asset_code,
            dsp_id: dsp,
            dsp_name: ((listDSP || [])?.find(({ id }) => id === dsp) || {})?.label,
        });
        setValue({
            asset_code: "",
            dsp: ""
        });
    };
    const isEditing = Boolean(preload);
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
            <DialogTitle>{isEditing ? "Edit" : "Add"} Asset ID</DialogTitle>
            <DialogContent dividers>
                <Box component="form" onSubmit={onSubmit}>
                    <Grid container direction="column" rowSpacing={1}>
                        <Grid item>
                            <FormLabel label="Asset ID" />
                            <TextInput
                                placeholder="Asset ID"
                                onChange={e => changeValue("asset_code", e?.target?.value)}
                                value={asset_code}
                            />
                        </Grid>
                        <Grid item>
                            <FormLabel label="DSP" />
                            <AutoCompleteComponent
                                options={listDSP}
                                optionKey="dsp_id"
                                optionLabel="name"
                                value={
                                    listDSP.find(
                                        option => option.id === Number(dsp)
                                    ) || null
                                }
                                size="small"
                                onChange={value => changeValue("dsp", value)}
                                width="100%"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="flex-end" padding="10px 16px" gap={2}>
                    <Grid item>
                        <SecondaryButton label="Cancel" onClick={onClose} />
                    </Grid>
                    <Grid item>
                        <PrimaryButton
                            label={isEditing ? "Save" : "Add"}
                            onClick={onSubmit}
                            disabled={!value}
                            color={value ? "black" : "white"}
                            textColor={value ? "white" : "black"}
                        />
                    </Grid>
                </Grid>
            </DialogActions>

        </Dialog>
    );
};

export default ModalAssetId;
