import { Chip, Grid, styled } from "@mui/material";
import { useState } from "react";

const ArrayChip = ({ list, max = 4, ...props }) => {
  const [showMore, setShowMore] = useState(false);
  if (!list || list?.length === 0) {
    return "-";
  } else {
    const maxList = list?.slice(0, max);
    const restList = list?.length > max;
    const rest = list?.slice(max - 1);
    return (
      <Grid container columnSpacing={1} rowSpacing={1}>
        {(showMore ? list : maxList).map((item, index) => (
          <Grid item key={index} {...props}>
            <ItemChip
              label={item?.name || item || "-"}
              size="small"
              backgroundColor={item?.color}
              variant="outlined"
              className={item?.className || ""}
            />
          </Grid>
        ))}
        {restList && !showMore && (
          <Grid item {...props}>
            <ItemChipMoreShowLess
              label={`+${rest.length - 1}`}
              onClick={() => setShowMore(true)}
              size="small"
            />
          </Grid>
        )}
        {showMore && (
          <Grid item {...props}>
            <ItemChipMoreShowLess
              label="Show Less"
              onClick={() => setShowMore(false)}
              size="small"
            />
          </Grid>
        )}
      </Grid>
    );
  }
};
const ItemChip = styled(Chip)(({ backgroundColor, color }) => ({
  color: backgroundColor,
  backgroundColor:
    backgroundColor === "#FFF" ? "#6b7280" : `${backgroundColor}10`,
}));

const ItemChipMoreShowLess = styled(Chip)(() => ({
  color: "#FFFFFF",
  backgroundColor: "#222424",
  "&:hover": {
    color: "black",
    border: "1px solid black",
  },
}));
export default ArrayChip;
