import { SET_USERLOGIN, SET_USERLOGOUT } from "../../../constants/types";

const initialState = {
    association: null,
    created_at: "",
    display_picture_url: "",
    email: "",
    is_password_change_flag: false,
    name: "",
    original_publisher: null,
    permissions: [],
    phone: "",
    publisher: {
        publisher_id: null,
        publisher_code: "",
        name: "",
        description: "",
        party_type_flag: "",
        additional_info: null,
    },
    role: {
        role_id: null,
        name: "",
        type: "",
        roles_for: "",
        is_active: false,
        additional_info: null,
    },
    role_id: null,
    updated_at: "",
    user_id: null,
    user_menu: [],
    user_roles: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_USERLOGIN:
            return {
                ...state,
                ...action.payload,
            };
        case SET_USERLOGOUT:
            return initialState;
        default:
            return state;
    }
}
