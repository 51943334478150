import {
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
  Box
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Page, PrimaryButton } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { useParams } from "react-router";
import { HeaderTitle } from "layouts";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const breadcrumbData = [
  {
    label: "Parameter",
    link: "/admin/parameter/pencipta",
  },
  {
    label: "Client Classification",
    link: "/admin/parameter/client-classification",
  },
  {
    label: "Edit Client Classification",
    active: true,
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  thePercent: {
    fontSize: "30px",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  btnSubmit: {
    backgroundColor: "black",

    color: "white",
    padding: "10px 28px",
    width: "106px",
    height: "40px",
    borderRadius: "6px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnPengaturanPencipta: {
    backgroundColor: "black",

    color: "white",
    padding: "10px 28px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnPreview: {
    backgroundColor: "black",
    marginLeft: "10px",
    color: "white",
    padding: "10px 28px",
    width: "106px",
    height: "40px",
    borderRadius: "6px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnDialog: {
    backgroundColor: "black",
    marginLeft: "10px",
    color: "white",
    padding: "10px 28px",
    width: "150px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
    fontStyle: "normal",
  },
  dropdown: {
    color: "black",
  },
  conTextBlack: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  textField: {
    "& .MuiOutlinedInput-input": {
      height: "1px",
    },
  },
  select: {
    "& .MuiSelect-root": {
      height: "4px",
    },
    "& .MuiSelect-select": {
      height: "4px",
    },
  },
  font12: {
    fontSize: "12px",
    color: "#A63124",
  },
  font24: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  textStyle: {
    marginTop: "5px",
    color: "#8f8f8f",
    fontSize: "14px",
  },
  card: {
    border: "1px solid #9AA2B1",
    borderRadius: "6px",
    boxShadow: "none",
  },
  mt5: {
    marginTop: "5px",
  },
  mtMin5: {
    marginTop: "-5px",
  },
  mt10: {
    marginTop: "10px",
  },
  mtMin10: {
    marginTop: "-10px",
  },
  mb5: {
    marginBottom: "5px",
  },
  mbMin5: {
    marginBottom: "-5px",
  },
  mb10: {
    marginBottom: "10px",
  },
  mbMin10: {
    marginBottom: "-10px",
  },
  mb15: {
    marginBottom: "15px",
  },
  mr5: {
    marginRight: "5px",
  },
  ml5: {
    marginLeft: "5px",
  },
  flex: {
    display: "flex",
  },
  between: {
    justifyContent: "space-between",
  },
  center: {
    justifyContent: "center",
  },
  color687083: {
    color: "#687083",
  },
  outline: {
    width: "100%",
    marginRight: "20px",
    height: "44px",
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
  },
  pointer: {
    cursor: "pointer",
  },
  paper: {
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    display: "flex",
    flexDirection: "column",
    background: "white",
    position: "absolute",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
    zIndex: "1000",
  },
  containerForm: {
    width: "300px",
    minHeight: "100px",
    maxHeight: "300px",
    marginTop: "40px",
    borderTop: "1px solid #E4E7EB",
    overflow: "scroll",
  },
  gap4: {
    display: "flex",
    gap: "4px",
    marginTop: "10px",
  },
  colorRed: {
    color: "red",
  },
  displayNone: {
    display: "none",
  },
  height38: {
    height: "38px",
  },
  mt100: {
    marginTop: "100px",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  width300: {
    width: "300px",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
}));

function EditClientClassification(props) {
  const ref = useRef(null);
  const { id } = useParams();
  const [error, setError] = useState({
    agentName: false,
    email: false,
    phone: false,
  });
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");

  const handleChange = e => {
    const value = e.target.value;
    const name = e.target.name;

    if (name === "agentName") {
      if (value === "") {
        setError({
          ...error,
          agentName: true,
        });
      } else {
        setError({
          ...error,
          agentName: false,
        });
      }
    } else if (name === "email") {
      if (value === "") {
        setError({
          ...error,
          email: true,
        });
      } else {
        setError({
          ...error,
          email: false,
        });
      }
    } else if (name === "phone") {
      if (value === "") {
        setError({
          ...error,
          phone: true,
        });
      } else {
        setError({
          ...error,
          phone: false,
        });
      }
    } else if (name === "name") {
      setName(value);
    } else if (name === "desc") {
      setDesc(value);
    }
  };

  const handleSaveData = e => {
    e.preventDefault();

    const url = `${hardBaseUrl}/publisher/classification/${id}`;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const data = {
      classification_name: name,
      description: desc,
    };

    axios
      .put(url, data, config)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Data has been updated",
        });
        props.history.push("/admin/parameter/client-classification");
      })
      .catch(err => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.errors[0].message,
        });
      });
  };

  const fetchDetail = () => {
    const url = `${hardBaseUrl}/publisher/classification/${id}`;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(url, config)
      .then(res => {
        const data = res.data.data;
        setName(data.classification_name);
        setDesc(data.description);
      })
      .catch(err => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.errors[0].message,
        });
      });
  };

  useEffect(() => {
    fetchDetail();
  }, []);

  const classes = useStyles();

  return (
    <Page className={classes.root} title="Edit Client Classification">
      <Container maxWidth={false}>
        <Box component="form" ref={ref}>
          <HeaderTitle
            title="Edit Client Classification"
            breadcrumbData={breadcrumbData}
            backButton
          />
          <Divider className={classes.divider} />
          <Box>
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Client Classification Information
              </Typography>
            </ThemeProvider>

            <ThemeProvider theme={theme}>
              <Typography component="p" className={classes.textStyle}>
                To edit Client Classification Information.
              </Typography>
            </ThemeProvider>
          </Box>

          <Grid container>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
              <Card className={classes.card}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Box className={(classes.mt5, classes.mb5)}>
                        <Box className={(classes.mt10, classes.mb10)}>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              Name
                            </Typography>
                          </ThemeProvider>
                        </Box>
                        <Box className={(classes.mt10, classes.mb10)}>
                          <TextField
                            id="outlined-basic"
                            placeholder="Name"
                            className={classes.textField}
                            variant="outlined"
                            fullWidth={true}
                            name="name"
                            onChange={handleChange}
                            value={name}
                          />
                          <small className={(classes.mt10, classes.mb10)}>
                            {error.agentName ? "Agent Name is required" : ""}
                          </small>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}></Grid>
                  </Grid>
                  <Box className={(classes.mt5, classes.mb5)}>
                    <Box className={(classes.mt10, classes.mb10)}>
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>
                          Description
                        </Typography>
                      </ThemeProvider>
                    </Box>
                    <Box className={(classes.mt10, classes.mb10)}>
                      <TextField
                        id="outlined-basic"
                        placeholder="Description"
                        className={classes.textField}
                        variant="outlined"
                        fullWidth={true}
                        name="desc"
                        onChange={handleChange}
                        multiline
                        minRows={4}
                        value={desc}
                      />
                      <small className={classes.colorRed}>
                        {error.agentName ? "Agent Name is required" : ""}
                      </small>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={8}>
              <Box className={classes.gap4} justifyContent="flex-end">
                <PrimaryButton onClick={e => handleSaveData(e)} label="Update" />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
}

export default EditClientClassification;
