import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import {
  FormLabel,
  PrimaryButton,
  SecondaryButton,
  TextInput,
} from "components";
import { useEffect, useState } from "react";
import { validateEmail } from "utils";

const ModalFormOriginalPublisher = ({
  open,
  onClose,
  preload,
  handleSubmit,
  loadingButton,
}) => {
  const [payload, setPayload] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [emailError, setEmailError] = useState(null);

  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
    if (key === "email") {
      setEmailError(null);
    }
  };
  const getPreload = () => {
    setPayload({
      original_publisher_id: preload?.original_publisher_id,
      name: preload?.name,
      phone: preload?.phone,
      email: preload?.email,
    });
  };

  const onSubmit = () => {
    let hasError = false;
    const { email } = payload;
    if (!validateEmail(email)) {
      setEmailError(
        "Invalid email, please enter your email address in format: yourname@example.com"
      );
      hasError = true;
    }

    if (hasError) return;
    handleSubmit(payload);
  };

  useEffect(() => {
    getPreload();
  }, [preload]);

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{`${
        preload ? "Edit" : "Add"
      } Original Publisher`}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" rowSpacing={1}>
          <Grid item>
            <FormLabel label="Original Publisher Name" required />
            <TextInput
              placeholder="Original Publisher Name"
              value={payload?.name}
              onChange={e => handleChangePayload(e?.target?.value, "name")}
              disabled={loadingButton}
            />
          </Grid>
          <Grid item>
            <FormLabel label="Phone Number" required />
            <TextInput
              placeholder="Phone Number"
              value={payload?.phone}
              onChange={e => handleChangePayload(e?.target?.value, "phone")}
              disabled={loadingButton}
            />
          </Grid>
          <Grid item>
            <FormLabel label="Email" required />
            <TextInput
              placeholder="Email"
              value={payload?.email}
              onChange={e => handleChangePayload(e?.target?.value, "email")}
              error={!!emailError}
              helperText={emailError}
              disabled={loadingButton}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <SecondaryButton label="Cancel" onClick={onClose} />
        <PrimaryButton
          label={
            loadingButton
              ? preload
                ? "Updating"
                : "Saving"
              : preload
              ? "Update"
              : "Save"
          }
          loading={loadingButton}
          disabled={loadingButton}
          onClick={onSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ModalFormOriginalPublisher;
