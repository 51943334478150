import { Container, Divider } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Grid } from "@mui/material";
import axios from "axios";
import {
  ButtonGroupTop,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SearchTextInput,
  SectionLabel,
  SkeletonComponent,
  SwitchInput,
  TableAction,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import ModalFormOriginalPublisher from "./Components/ModalFormOriginalPublisher";

function OriginalPublisher() {
  const classes = globalStyles();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
    search: "",
  });
  const [loadingButton, setLoadingButton] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [preload, setPreload] = useState(false);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const handleCloseModal = () => setModalVisible(false);

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/publisher/originalpublisher`,
        {
          headers,
          params: queryParams,
        }
      );
      const { data, meta } = res?.data;
      setDataTable(data || []);
      const pageCount = getTotalPage(meta?.total, queryParams?.size);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setLoadingPage(false);
    }
  };

  const handleRestore = async (checked, id) => {
    try {
      setLoadingPage(true);
      await axios.put(
        `${hardBaseUrl}/publisher/originalpublisher/status/${id}`,
        { is_active: checked },
        { headers }
      );
      ModalSuccess(
        `Succesfully ${checked ? "restore" : "deactivate"} original publisher`
      ).then(() => getDataTable());
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setLoadingPage(false);
    }
  };
  const handleDelete = async item => {
    try {
      setLoadingPage(true);
      await axios.delete(
        `${hardBaseUrl}/publisher/originalpublisher/${item.original_publisher_id}`,
        { headers }
      );
      ModalSuccess("Succesfully delete original publisher").then(() =>
        getDataTable()
      );
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setLoadingPage(false);
    }
  };
  const handleSubmit = async payload => {
    const method = preload ? axios.put : axios.post;
    try {
      setLoadingButton(true);
      await method(
        `${hardBaseUrl}/publisher/originalpublisher${
          preload ? `/${payload?.original_publisher_id}` : ""
        }`,
        payload,
        { headers }
      );
      ModalSuccess(
        `Succesfully ${preload ? "update" : "save"} original publisher`
      ).then(() => getDataTable());
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      handleCloseModal();
      setLoadingButton(false);
    }
  };

  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  useEffect(() => {
    const isSearching = Boolean(queryParams?.search);
    if (isSearching) {
      debounceDataTable();
      return () => {
        debounceDataTable.cancel();
      };
    } else {
      getDataTable();
    }
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Original Publisher">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false} className={classes.container}>
          <HeaderTitle title="Configuration" breadcrumbData={breadcrumbData} />
          <ButtonGroupTop />
          <Divider className={classes.divider} />
          <SectionLabel
            title="List Original Publisher"
            subTitle={
              "To add data for original publishers who have collaborated"
            }
          />
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            my="16px"
          >
            <Grid item>
              <SearchTextInput
                placeholder="Search"
                value={queryParams?.search}
                onChange={e =>
                  handleChangeQueryParams(e?.target?.value, "search")
                }
              />
            </Grid>
            <Grid item>
              <PrimaryButton
                label="Add Original Publisher"
                onClick={() => {
                  setModalVisible(true);
                  setPreload(null);
                }}
                startIcon={<Add />}
              />
            </Grid>
          </Grid>
          <InnoTableV2
            isLoading={false}
            columns={columnTable({ handleRestore })}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.size}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeQueryParams(e?.target?.value, "size")
            }
            isHaveAction
            renderAction={item => (
              <TableAction
                handleEdit={() => {
                  setModalVisible(true);
                  setPreload(item);
                }}
                handleDelete={() => handleDelete(item)}
                deleteConfirmation
                deleteConfirmationKey={item?.name}
                deleteConfirmationTitle="Delete Original Publisher"
              />
            )}
          />
        </Container>
      )}
      {modalVisible && (
        <ModalFormOriginalPublisher
          open={modalVisible}
          onClose={handleCloseModal}
          preload={preload}
          loadingButton={loadingButton}
          handleSubmit={handleSubmit}
        />
      )}
    </Page>
  );
}

const breadcrumbData = [
  {
    label: "Configuration",
    link: "/admin/konfigurasi/original-publisher",
  },
  {
    label: "Original Publisher",
    active: true,
  },
];
const columnTable = ({ handleRestore }) => [
  {
    name: "all",
    title: "Status",
    renderText: item => (
      <SwitchInput
        checked={item?.is_active_flag}
        onChange={e =>
          handleRestore(e.target.checked, item?.original_publisher_id)
        }
      />
    ),
  },
  {
    name: "name",
    title: "Original-Publisher Name",
    componentType: "text",
  },
  {
    name: "phone",
    title: "Phone Number",
    componentType: "text",
  },
  {
    name: "email",
    title: "Email",
    componentType: "text",
  },
];

export default OriginalPublisher;
