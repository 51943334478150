import { SET_USERLOGIN, SET_USERLOGOUT } from "../types";

export const setUserState = (payload) => ({
    type: SET_USERLOGIN,
    payload,
});

export const setUserLogout = () => ({
    type: SET_USERLOGOUT,
});
