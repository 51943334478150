import {
  CircularProgress,
  Container,
  Divider,
  Tooltip,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Grid, styled } from "@mui/material";
import axios from "axios";
import {
  ArrayChip,
  AutoCompleteWithSearch,
  ButtonGroupTop,
  ImportDocumentModal,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SearchTextInput,
  SkeletonComponent,
  TableAction,
} from "components";
import { InnoTable } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import DownloadLogo from "../../../../assets/img/download.png";
import ExcelLogo from "../../../../assets/img/excel-logo.png";

function Lagu({ userLogin }) {
  const classes = globalStyles();
  const history = useHistory();
  const location = useLocation();
  const roleType = userLogin.role.type;
  const selectPublisher = roleType === "association" || roleType === "society";
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsSearch = urlParams.get("search");
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("size");
  const paramsComposer = urlParams.get("composer_id");

  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [modalDocument, setModalDocument] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: Number(paramsPage) || 1,
    size: Number(paramsSize) || 10,
    search: paramsSearch || "",
    original_publisher_id: "",
    sort: 0,
    composer_id: paramsComposer || "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [searchComposer, setSearchComposer] = useState("");
  const [optionComposer, setOptionComposer] = useState([]);

  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
    handleChangePageParams(value, key);
    if (key !== "page") {
      handleChangePageParams(1, "page");
    }
  };

  const [loadingButton, setLoadingButton] = useState(false);
  const [selectedPublisherId, setSelectedPublisherId] = useState(null);
  const [societyFormat, setSocietyFormat] = useState("asaba");

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/songs`, {
        headers,
        params: queryParams,
      });

      const { data, meta } = res?.data;
      setDataTable(data);
      const pageCount = getTotalPage(meta?.total, queryParams?.size || 1);
      setTableTotalPage(pageCount);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const getOptionComposer = async () => {
    try {
      setLoadingFilter(true);
      const res = await axios.get(`${hardBaseUrl}/composers`, {
        headers,
        params: {
          page: 1,
          size: 50,
          ...(searchComposer && { search: searchComposer }),
        },
      });
      const modifiedData = res?.data?.data?.map(item => ({
        ...item,
        id: item?.composer_id,
        label: item?.sure_name,
      }));
      setOptionComposer(modifiedData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingFilter(false);
    }
  };
  const debounceOptionComposer = useCallback(
    debounce(() => {
      getOptionComposer();
    }, 500),
    [searchComposer]
  );
  const handleDownloadDocument = async () => {
    try {
      setLoadingButton(true);
      const res = await axios.get(
        `${hardBaseUrl}/publisher/song/export/${queryParams?.composer_id || 0}`,
        { headers }
      );
      ModalSuccess(res.data?.message);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingButton(false);
    }
  };
  const handleUploadDocument = async documentFiles => {
    const formData = new FormData();
    formData.append("files", documentFiles[0]);
    formData.append("format", societyFormat);
    if (societyFormat === "asaba") {
      formData.append("publisher_id", selectedPublisherId);
    }
    try {
      setLoadingButton(true);
      await axios.post(`${hardBaseUrl}/publisher/song/import`, formData, {
        headers,
      });
      ModalSuccess(
        "You can check the progress of the upload process in the Upload Monitoring Menu",
        "Data is being processed"
      ).then(() => {
        getDataTable();
      });
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingButton(false);
      setModalDocument(false);
    }
  };

  useEffect(() => {
    debounceDataTable();
    if (queryParams?.page !== paramsPage) {
      handleChangePageParams(queryParams?.page, "page");
    }
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, debounceDataTable]);
  useEffect(() => {
    debounceOptionComposer();
    return () => {
      debounceOptionComposer.cancel();
    };
  }, [searchComposer, debounceOptionComposer]);

  return (
    <Page className={classes.root} title="Song">
      <Container maxWidth={false}>
        <HeaderTitle title="Parameter" breadcrumbData={breadcrumbData} />
        <ButtonGroupTop />
        <CustomDivider />
        <Grid container justifyContent="space-between" mb="16px">
          <Grid item>
            <Grid container columnSpacing={1}>
              <Grid item>
                <SearchTextInput
                  placeholder="Search"
                  value={queryParams?.search}
                  onChange={e =>
                    handleChangeQueryParams(e?.target?.value, "search")
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container columnSpacing={1} alignItems="center">
              <Grid item>
                <AutoCompleteWithSearch
                  label="Composer/Author"
                  options={optionComposer}
                  optionLabel="sure_name"
                  loading={loadingFilter}
                  inputValue={searchComposer}
                  onInputChange={setSearchComposer}
                  value={
                    optionComposer?.find(
                      option =>
                        option?.composer_id === queryParams?.composer_id
                    ) || null
                  }
                  onChange={item =>
                    handleChangeQueryParams(item?.composer_id, "composer_id")
                  }
                />
              </Grid>
              <Tooltip title="Download Document">
                <Grid item>
                  <PrimaryButton
                    onClick={handleDownloadDocument}
                    disabled={loadingButton}
                    label={
                      loadingButton ? (
                        <CircularProgress size="24px" color="inherit" />
                      ) : (
                        <img src={DownloadLogo} alt="logo" />
                      )
                    }
                    height="42px"
                  />
                </Grid>
              </Tooltip>
              <Tooltip title="Import Document">
                <Grid item>
                  <PrimaryButton
                    onClick={() => setModalDocument(true)}
                    label={<img src={ExcelLogo} alt="logo" />}
                    height="42px"
                    color="#34774C"
                  />
                </Grid>
              </Tooltip>
              <Grid item>
                <PrimaryButton
                  label="Add Songs"
                  onClick={() => history.push("/admin/parameter/lagu/tambah")}
                  startIcon={<AddIcon />}
                  size="large"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {loadingPage ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <InnoTable
            isLoading={false}
            columns={columnTable}
            items={dataTable || []}
            page={queryParams?.page}
            rowsPerPage={queryParams?.size}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeQueryParams(e?.target?.value, "size")
            }
            isHaveAction
            renderAction={item => (
              <TableAction
                handleEdit={() =>
                  history.push(`/admin/parameter/lagu/edit/${item?.song_id}`)
                }
              />
            )}
          />
        )}
      </Container>
      {modalDocument && (
        <ImportDocumentModal
          open={modalDocument}
          onClose={() => setModalDocument(false)}
          onSubmit={handleUploadDocument}
          fileType=".xlsx, .xls"
          loadingButton={loadingButton}
          downloadFile
          roleType={roleType}
          onChangePublisher={
            selectPublisher ? value => setSelectedPublisherId(value) : null
          }
          onChangeFormat={
            societyFormat ? value => setSocietyFormat(value) : null
          }
        />
      )}
    </Page>
  );
}

const CustomDivider = styled(Divider)(() => ({
  marginBottom: 16,
  borderTop: "1px solid #e1e1e1",
}));

const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Parameter",
    link: "/admin/parameter/lagu",
  },
  {
    label: "Song",
    active: true,
  },
];
const columnTable = [
  {
    name: "title_song",
    title: "Song Title",
    convertFunction: null,
  },
  {
    name: "song_aliases",
    title: "Alias Song Title",
    renderText: item => <ArrayChip list={item} />,
  },
  {
    name: "publisher_name",
    title: "Publisher",
    renderText: item => item || "-",
  },
  {
    name: "original_publisher_name",
    title: "Original Publisher",
    renderText: item => item || "-",
  },
  { name: "iswc_code", title: "ISWC", renderText: item => item || "-" },
  {
    name: "all",
    title: "Composer/Author",
    renderText: item => (
      <ArrayChip
        list={item?.song_composer?.map(
          composer => composer?.composer?.sure_name
        )}
      />
    ),
  },
  {
    name: "song_composer",
    title: "Composer Alias Name",
    componentType: "chip",
    selectedKeyInside: "alias_names",
  },
];
export default Lagu;
