import { Container, Divider } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@mui/material";
import axios from "axios";
import {
  ButtonGroupTop,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SearchTextInput,
  SelectInput,
  SkeletonComponent,
  TableAction,
} from "components";
import { InnoTable } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { getErrors } from "../../../../utils";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    marginBottom: "16px",
    borderTop: "1px solid #e1e1e1",
  },
}));

const getSubdomain = () => {
  const host = window.location.hostname;
  const subdomain = host.split(".")[0];
  // for non local
  if (!host.includes("localhost")) {
    if (host.includes("caris.id")) {
      return subdomain;
    } else {
      return "mpis";
    }
  } else {
    //for local
    if (host.includes(".localhost")) {
      return subdomain;
    } else {
      return "mpis";
    }
  }
};

function User() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const isMpis = getSubdomain() === "mpis";
  const usedUserApi = isMpis ? "/user" : "/publisher/user";
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsSearch = urlParams.get("search");
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("size");
  const paramsRole = urlParams.get("role");
  const [loadingPage, setLoadingPage] = useState(false);
  const [optionRole, setOptionRole] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: Number(paramsPage) || 1,
    size: Number(paramsSize) || 10,
    search: paramsSearch || "",
    role_id: paramsRole || "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const handleChangeSearch = event => {
    const value = event?.target?.value;
    handleChangeQueryParams(value, "search");
    handleChangePageParams(value, "search");
  };
  const handleChangePage = page => {
    handleChangeQueryParams(page, "page");
    handleChangePageParams(page, "page");
  };
  const handleChangePageSize = size => {
    handleChangeQueryParams(size, "size");
    handleChangePageParams(size, "size");
  };
  const handleChangeRole = id => {
    handleChangeQueryParams(id, "role_id");
    handleChangePageParams(id, "role");
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}${usedUserApi}`, {
        headers,
        params: {
          ...queryParams,
          ...(isMpis && { type: "admin" }),
        },
      });

      const { data, meta } = res?.data;
      setDataTable(data);
      const pageCount = getTotalPage(meta?.total, queryParams?.size || 1);
      setTableTotalPage(pageCount);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const getOptionRole = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/role`, {
        headers,
        params: {
          type: isMpis ? "admin" : "publisher",
        },
      });
      setOptionRole(res?.data?.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const handleDelete = async ({ user_id }) => {
    try {
      setLoadingPage(true);
      const res = await axios.delete(`${hardBaseUrl}${usedUserApi}${user_id}`, {
        headers,
      });
      if (res.data.message === "success") {
        ModalSuccess("Data Deleted Successfully").then(result => {
          if (result.isConfirmed === true) {
            getDataTable();
          }
        });
      }
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getOptionRole();
  }, []);
  useEffect(() => {
    debounceDataTable();
    if (queryParams?.page !== paramsPage) {
      handleChangePageParams(queryParams?.page, "page");
    }
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="User">
      <Container maxWidth={false}>
        <HeaderTitle title="Parameter" breadcrumbData={breadcrumbData} />
        <ButtonGroupTop />
        <Divider className={classes.divider} />
        <Grid container justifyContent="space-between" mb="16px">
          <Grid item>
            <SearchTextInput
              placeholder="Search"
              value={queryParams?.search}
              onChange={handleChangeSearch}
            />
          </Grid>
          <Grid item>
            <Grid container columnSpacing={1} alignItems="center">
              <Grid item>
                <SelectInput
                  label="Role"
                  options={optionRole}
                  optionKey="role_id"
                  optionLabel="name"
                  value={queryParams?.role_id || ""}
                  onChange={e => handleChangeRole(e?.target?.value)}
                  placeholder="All Role"
                  width={200}
                />
              </Grid>
              <Grid item>
                <PrimaryButton
                  label="Add User"
                  startIcon={<Add />}
                  onClick={() => history.push("/admin/parameter/user/tambah")}
                  size="large"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {loadingPage ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <InnoTable
            isLoading={false}
            columns={columnTable}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.size}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) => handleChangePage(page)}
            handleChangeRowsPerPage={e =>
              handleChangePageSize(e?.target?.value)
            }
            isHaveAction
            renderAction={item => (
              <TableAction
                handleEdit={() =>
                  history.push(`/admin/parameter/user/edit/${item.user_id}`)
                }
                deleteConfirmation
                deleteConfirmationTitle="Delete User"
                deleteConfirmationKey={item?.name}
                handleDelete={() => handleDelete(item)}
              />
            )}
          />
        )}
      </Container>
    </Page>
  );
}

const columnTable = [
  {
    name: "name",
    title: "User Name",
  },
  {
    name: "role",
    title: "Role",
    renderText: item => item?.name,
  },
  {
    name: "phone",
    title: "Phone Number",
  },
  {
    name: "email",
    title: "Email",
  },
];
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Parameter",
    link: "/admin/parameter/user",
  },
  {
    label: "User",
    active: true,
  },
];
export default User;
