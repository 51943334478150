import { Container, Divider } from "@material-ui/core";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import {
  AutoCompleteWithSearch,
  CheckboxInput,
  CurrencyDisplay,
  CurrencyNumberInput,
  DatePicker,
  FormLabel,
  ModalError,
  ModalSuccess,
  MultipleSelectInputWithTags,
  Page,
  PrimaryButton,
  SectionLabel,
  SelectInput,
  SkeletonComponent,
  SwitchInput,
  TextInput,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import {
  arrayToCommaSeparatedString,
  formatPayloadDate,
  getErrors,
} from "utils";

const AddPayment = () => {
  const classes = globalStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [useTaxes, setUseTaxes] = useState(false);
  const [dataTableTax, setDataTableTax] = useState([]);
  const [selectedTaxes, setSelectedTaxes] = useState([]);
  const [optionComposer, setOptionComposer] = useState([]);
  const [payload, setPayload] = useState({
    type: "claim",
    transaction_name: "payment",
    composer_id: null,
    invoice_date: moment(),
    note: "",
    revenue: 0,
  });
  const [listInvoice, setListInvoice] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const {
    composer_id,
    invoice_date,
    note,
    revenue,
    type,
    ...restPayload
  } = payload;
  const [hasMore, setHasMore] = useState(true);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 20,
    search: "",
  });
  let invoiceClaim = type === "claim";

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleSelectTax = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedTaxes(prev =>
      checked ? [...prev, option] : prev.filter(item => item?.id !== option?.id)
    );
  };
  const handleSelectTransaction = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedInvoices(prev =>
      checked
        ? [...prev, option]
        : prev.filter(item => item?.transaction_id !== option?.transaction_id)
    );
  };

  const getOptionComposer = async () => {
    try {
      setLoadingFilter(true);
      const res = await axios.get(`${hardBaseUrl}/composers`, {
        headers,
        params: queryParams,
      });
      const { data, meta } = res?.data;
      const newOptions = data?.map(item => ({
        ...item,
        id: item?.performer_id,
      }));
      setOptionComposer(prev =>
        queryParams.page === 1 ? newOptions : [...prev, ...newOptions]
      );
      setHasMore(meta?.total > newOptions.length);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingFilter(false);
    }
  };
  const getDataTableTax = async () => {
    setLoadingPage(true);
    try {
      const res = await axios.get(
        `${hardBaseUrl}/publisher/transaction/payment/taxes`,
        { headers }
      );
      setDataTableTax(res.data.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getInvoiceList = async () => {
    try {
      const res = await axios.get(
        `${hardBaseUrl}/publisher/transaction/invoice/usage`,
        {
          headers,
          params: { composer_id },
        }
      );
      const modifiedData = res?.data?.data?.flatMap(item =>
        item.songs.map(song => ({
          transaction_id: item.transaction_id,
          transaction_number: item.transaction_number,
          client_name: item.client_name,
          ...song,
        }))
      );
      setListInvoice(modifiedData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const validatePayload = () => {
    const errors = [];
    const errorEmpty = err => `${err} can't be empty.`;

    Object.entries(payload).forEach(([key, value]) => {
      switch (key) {
        case "composer_id":
          if (!value) errors.push("Please select composer");
          break;
        case "revenue":
          if (!value && invoiceClaim) errors.push(errorEmpty("Value"));
          break;
        default:
          break;
      }
    });
    if (useTaxes && !selectedTaxes.length) errors.push("Please select tax");

    return errors;
  };
  const handleSubmit = async () => {
    const errors = validatePayload();
    if (errors?.length) {
      ModalError(errors[0]);
      return;
    }
    const modifiedPayload = {
      ...restPayload,
      invoice_date: formatPayloadDate(invoice_date),
      ...(useTaxes && { taxes: selectedTaxes.map(tax => tax.id) }),
      ...(invoiceClaim
        ? { revenue, note }
        : {
            usage_transaction_id: selectedInvoices?.map(
              item => item?.transaction_id
            ),
            usage_description: selectedInvoices?.reduce((acc, invoice) => {
              acc[invoice?.transaction_id] = invoice?.description;
              return acc;
            }, {}),
          }),
    };
    try {
      setSubmitting(true);
      const res = await axios.post(
        `${hardBaseUrl}/publisher/transaction`,
        modifiedPayload,
        { headers }
      );
      ModalSuccess(res?.data?.message).then(() => history.goBack());
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    getDataTableTax();
  }, []);
  useEffect(() => {
    if (!invoiceClaim) getInvoiceList();
  }, [composer_id]);
  useEffect(() => {
    getOptionComposer();
  }, [queryParams]);

  return (
    <Page className={classes.root} title="Payment">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Payment Transaction"
            breadcrumbData={breadcrumbData}
            backButton
          />
          <Divider className={classes.divider} />
          <SectionLabel
            title="Create Transaction"
            subTitle="Feature for creating invoices for song usage claims"
            mb="16px"
          />
          <FormLabel label="Transaction Type" />
          <SelectInput
            value={type}
            options={optionTransaction}
            optionKey="value"
            optionLabel="label"
            onChange={e => handleChangePayload(e?.target?.value, "type")}
            width={200}
            disabled={submitting}
          />

          <Box border="1px solid #ebebeb" borderRadius="5px" p="24px" my="16px">
            <Grid container columnSpacing={2} rowSpacing={1}>
              <Grid item xs={12} md={6}>
                <FormLabel label="Date" required />
                <DatePicker
                  value={invoice_date}
                  onChange={date => handleChangePayload(date, "invoice_date")}
                  disabled={submitting}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="Composer/Author Name" required />
                <AutoCompleteWithSearch
                  loading={loadingFilter}
                  options={optionComposer}
                  value={composer_id}
                  onChange={value => handleChangePayload(value, "composer_id")}
                  inputValue={queryParams?.search}
                  onInputChange={value =>
                    handleChangeQueryParams(value, "search")
                  }
                  optionLabel="sure_name"
                  placeholder="Search Composer/Author"
                  setQueryParams={setQueryParams}
                  hasMore={hasMore}
                  disabled={submitting}
                  width="100%"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormLabel
                  label={invoiceClaim ? "Value" : "Client Transaction"}
                  required
                />
                {invoiceClaim ? (
                  <CurrencyNumberInput
                    value={revenue}
                    onChange={value => handleChangePayload(value, "revenue")}
                    startAdornment="Rp"
                    disabled={submitting}
                  />
                ) : (
                  <MultipleSelectInputWithTags
                    options={listInvoice}
                    placeholder="Choose Transaction"
                    value={selectedInvoices}
                    textValue={arrayToCommaSeparatedString(
                      selectedInvoices,
                      "transaction_number"
                    )}
                    optionKey="transaction_id"
                    optionLabel="transaction_number"
                    checkBoxLabel="transaction_number"
                    onChange={handleSelectTransaction}
                    width="100%"
                    disabled={submitting}
                  />
                )}
              </Grid>
              <Grid item xs={0} md={6} />
              <Grid item xs={12}>
                {invoiceClaim ? (
                  <>
                    <FormLabel label="Description" />
                    <TextInput
                      placeholder="Description"
                      value={note}
                      onChange={e =>
                        handleChangePayload(e?.target?.value, "note")
                      }
                      multiline
                      rows={4}
                      disabled={submitting}
                    />
                  </>
                ) : (
                  Boolean(selectedInvoices?.length) && (
                    <Box mt="16px">
                      <InnoTableV2
                        isLoading={false}
                        columns={columnTableInvoice}
                        items={selectedInvoices}
                      />
                    </Box>
                  )
                )}
              </Grid>
            </Grid>
          </Box>
          <SwitchInput
            checked={useTaxes}
            onChange={e => setUseTaxes(e.target.checked)}
            label="Use taxes in this Transaction"
            disabled={submitting}
          />
          {useTaxes && (
            <Box my="16px">
              <InnoTableV2
                isLoading={false}
                columns={columnTableTax}
                items={dataTableTax}
                isHaveAction
                renderAction={item => (
                  <CheckboxInput
                    checked={selectedTaxes?.some(
                      selected => selected?.id === item?.id
                    )}
                    onChange={e => handleSelectTax(e, item)}
                    disabled={submitting}
                  />
                )}
              />
            </Box>
          )}
          <Grid container justifyContent="right" my="16px">
            <PrimaryButton
              label={submitting ? "Submitting" : "Add"}
              loading={submitting}
              disabled={submitting}
              onClick={handleSubmit}
            />
          </Grid>
        </Container>
      )}
    </Page>
  );
};

const breadcrumbData = [
  {
    label: "Transaction",
    link: "/admin/payment",
  },
  {
    label: "Create Transaction",
    active: true,
  },
];
const optionTransaction = [
  {
    value: "claim",
    label: "Claim",
  },
  {
    value: "usage",
    label: "Usage",
  },
];
const columnTableInvoice = [
  {
    name: "transaction_number",
    title: "Invoice",
    renderText: item => item || "-",
  },
  {
    name: "title",
    title: "Song",
    renderText: item => item || "-",
  },
  {
    name: "publisher_percentage",
    title: "Publisher Percentage",
    renderText: item => `${item || 0} %`,
  },
  {
    name: "fee",
    title: "Fee",
    headerAlign: "right",
    renderText: item => <CurrencyDisplay value={item} prefix="Rp " />,
  },
  {
    name: "value",
    title: "Value",
    headerAlign: "right",
    renderText: item => <CurrencyDisplay value={item} prefix="Rp " />,
  },
  {
    name: "description",
    title: "Description",
    renderText: item => item || "-",
  },
];
const columnTableTax = [
  {
    name: "code",
    title: "Code",
  },
  {
    name: "description",
    title: "Description",
    renderText: item => item || "-",
  },
  {
    name: "rate",
    title: "Rate",
    renderText: item => `${item} %`,
  },
];

export default AddPayment;
