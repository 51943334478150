import { FormControlLabel, styled, Switch } from "@mui/material";
import { useSelector } from "react-redux";

const SwitchInput = ({ checked, onChange, label, color, ...rest }) => {
  const userState = useSelector(state => state?.userState);
  const theme = userState?.publisher?.theme_color;
  return (
    <FormControlLabel
      control={
        <CustomSwitch
          checked={checked}
          onChange={onChange}
          customColor={color || theme}
          {...rest}
        />
      }
      label={label}
    />
  );
};
const CustomSwitch = styled(Switch)(({ customColor }) => ({
  marginLeft: "10px",
  width: "40px",
  height: "20px",
  borderRadius: "10px",
  padding: 0,
  marginRight: "6px",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 16,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(20px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: `${customColor || "#111827"}`,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: "16px",
    height: "16px",
    borderRadius: "100%",
    backgroundColor: "white",
  },
  "& .MuiSwitch-track": {
    borderRadius: "10px",
    opacity: 1,
    backgroundColor: "#E4E7EB",
    boxSizing: "border-box",
  },
  "& .MuiSwitch-switchBase.Mui-disabled": {
    "& + .MuiSwitch-track": {
      cursor: "not-allowed",
    },
  },
}));

export default SwitchInput;
