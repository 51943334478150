import { Divider } from "@material-ui/core";
import { BackupOutlined } from "@material-ui/icons";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import {
  AttachmentFilesCard,
  ModalError,
  PrimaryButton,
  SecondaryButton,
  SectionLabel,
} from "components";
import { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import { formatFileSize } from "utils";

const ModalBulkAdd = ({
  open,
  onClose,
  onSubmit,
  loadingButton,
  modalTitle,
  endPoint,
}) => {
  const classes = globalStyles();
  const token = localStorage.getItem("token");
  const [files, setFiles] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length === 1) {
      setFiles(acceptedFiles);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: [".xls", ".xlsx"],
  });

  const handleDownloadTemplate = async () => {
    const url = `${hardBaseUrl}/${endPoint ||
      "publisher/transaction-song/template"}`;
    try {
      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "template.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      onClose();
      ModalError("Error downloading the template");
    }
  };
  const handleSubmit = () => {
    onSubmit(files);
  };

  const dropzoneStyle = useMemo(() => {
    return isDragActive ? classes?.dragActive : "";
  }, [isDragActive, classes.activeStyle]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>
        <Typography fontSize={16} fontWeight={700}>
          {modalTitle || "Import Document"}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <SectionLabel title="Upload Document" mb="16px" />
        <div
          {...getRootProps({
            className: `${classes.dragFilesContainer} ${dropzoneStyle}`,
          })}
        >
          <input {...getInputProps()} />
          <BackupOutlined className={classes?.uploadIcon} />
          <Typography>
            {isDragActive
              ? "Drop the files here..."
              : "Drag files or click to upload"}
          </Typography>
        </div>
        <Box my="16px">
          <PrimaryButton
            label="Download Template"
            onClick={handleDownloadTemplate}
            disabled={loadingButton}
          />
        </Box>
        {files?.length > 0 && (
          <Box my="16px">
            <SectionLabel title="Uploaded File" />
            <Grid container direction="column" rowSpacing={1} mt="8px">
              {files?.map(({ name, file_path, size }, index) => (
                <Grid item key={index}>
                  <AttachmentFilesCard
                    fileName={name}
                    fileSize={formatFileSize(size)}
                    filePath={file_path}
                    tooltipRemove="Remove File"
                    handleRemove={() => setFiles([])}
                  />
                </Grid>
              ))}
            </Grid>
            <Divider />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <SecondaryButton
          onClick={onClose}
          label="Cancel"
          disabled={loadingButton}
        />
        <PrimaryButton
          onClick={handleSubmit}
          label={loadingButton ? "Uploading" : "Upload"}
          disabled={loadingButton}
          loading={loadingButton}
        />
      </DialogActions>
    </Dialog>
  );
};
export default ModalBulkAdd;
