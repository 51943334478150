import { combineReducers } from "redux";
import sessionReducer from "./auth/sessionReducer";
import auth from "./auth/authReducer";
import message from "./configuration/messageReducer";
import konfigurasiMenu from "./landingPage/konfigurasiMenuReducer";
import masterMenu from "./landingPage/masterMenuReducer";
import publisherId from "./configuration/publisherIdReducer";
import langReducer from "./landingPage/langReducer";
import manajemenWeb from "./landingPage/manajemenWebReducer";
import publisherBeritaIklan from "./landingPage/publisherBerta&IklanReducer";
import newsAds from "./news&ads";
import userState from "./userState";

const rootReducer = combineReducers({
  session: sessionReducer,
  auth,
  message,
  konfigurasiMenu,
  masterMenu,
  publisherId,
  langReducer,
  manajemenWeb,
  publisherBeritaIklan,
  newsAds,
  userState
});

export default rootReducer;
