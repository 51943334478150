import { Divider } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { Box, Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import axios from "axios";
import {
  ArrayChip,
  CustomTable,
  ModalError,
  PrimaryButton,
  SearchTextInput,
  SelectInput,
} from "components";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import { getErrors } from "utils";

const ModalAddContractSong = ({
  visiblity,
  handleClose,
  rowClick,
  preloadId,
  selectedSong,
  endPoint,
  filterOriginalPublisher,
}) => {
  const classes = globalStyles();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
    search: "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}${endPoint || "/publisher/contract-get-song"}`,
        {
          headers,
          params: {
            ...queryParams,
            ...(preloadId && {
              id: preloadId,
            }),
          },
        }
      );
      const { data, meta } = res?.data;
      setDataTable(data || []);
      const pageCount = getTotalPage(meta?.total, queryParams?.size);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  useEffect(() => {
    const isSearching = Boolean(queryParams?.search);
    if (isSearching) {
      debounceDataTable();
      return () => {
        debounceDataTable.cancel();
      };
    } else {
      getDataTable();
    }
  }, [queryParams, debounceDataTable]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={visiblity}
      onClose={handleClose}
    >
      <DialogContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <SearchTextInput
              fullWidth
              placeholder="Search Song"
              value={queryParams?.search}
              onChange={e => {
                handleChangeQueryParams(e?.target?.value, "search");
              }}
            />
          </Grid>
          {filterOriginalPublisher && (
            <Grid item>
              <SelectInput
                label="Original Publisher"
                options={[]}
                width={200}
              />
            </Grid>
          )}
        </Grid>
        <Divider className={classes?.divider} />
        <Box mt="24px">
          {dataTable && (
            <CustomTable
              columnTable={columnTable({
                selectedSong,
                filterOriginalPublisher,
              })}
              data={dataTable}
              rowClick={value => rowClick(value)}
              items={dataTable}
              page={queryParams?.page}
              pageSize={queryParams?.size}
              pageCount={tableTotalPage}
              handleChangePage={(_, page) =>
                handleChangeQueryParams(page, "page")
              }
              handleChangePageSize={e =>
                handleChangeQueryParams(e?.target?.value, "size")
              }
              isLoading={loadingPage}
              classes={classes}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <PrimaryButton onClick={handleClose} label="Close" />
      </DialogActions>
    </Dialog>
  );
};

const columnTable = ({ selectedSong, filterOriginalPublisher }) => [
  {
    name: "iswc_code",
    title: "ISWC Code",
  },
  {
    name: "title_song",
    title: "Song Title",
  },
  {
    name: "all",
    title: "Composer/Author Name",
    renderText: item => (
      <ArrayChip
        list={item?.song_composer?.map(
          composer => composer?.composer?.sure_name
        )}
      />
    ),
  },
  ...(filterOriginalPublisher
    ? [
        {
          name: "original_publisher_name",
          title: "Original Publisher",
        },
      ]
    : []),
  {
    name: "all",
    title: "",
    renderText: item =>
      selectedSong?.some(selected => selected?.song_id === item?.song_id) ? (
        <Check />
      ) : (
        ""
      ),
  },
];

export default ModalAddContractSong;
